import { axios } from '@/plugins/axios';

export const getCompanies = (params) => axios.get(`/companies`, {params: params})
  .then(res => res.data);

export const getCompany = (id) => axios.get(`/companies/${id}`)
  .then(res => res.data);

export const listCompanies = () => axios.get(`/companies/lists`)
  .then(res => res.data)
  .then(companies => companies || []);

export const searchCompanies = ( param ) => axios.get(`/companies/search?key=${ param }`)
  .then(res => res.data);

export const createCompany = (company, agent) => {
  let obj = {
    country_id    : company.country_id,
    name          : company.name,
    email         : company.email,
    business_name : company.business_name,
    nif           : company.nif,
    street        : company.street,
    number        : company.number,
    complement    : company.complement,
    district      : company.district,
    zipcode       : company.zipcode,
    city          : company.city,
    state         : company.state,
    phone         : company.phone,

    agent_name     : agent.name,
    agent_email    : agent.email,
    agent_password : agent.password
  };

  return axios
    .post(`/companies`, obj)
    .then(res => {
      if (res.data) {
        return res.data;
      }
      return {};
    });
};

export const updateCompany = (company) => {
  let obj = {
    country_id    : company.country_id,
    name          : company.name,
    email         : company.email,
    business_name : company.business_name,
    nif           : company.nif,
    street        : company.street,
    number        : company.number,
    complement    : company.complement,
    district      : company.district,
    zipcode       : company.zipcode,
    city          : company.city,
    state         : company.state,
    phone         : company.phone
  };

  return axios
    .put(`/companies/${company.id}`, obj)
    .then(res => {
      if (res.data) {
        return res.data;
      }
      return {};
    });
};

export const getCompanyCreditCards = (id) => axios.get(`/companies/${id}/credit-cards`)
  .then(res => {
    if (res.data) {
      return res.data;
    }
    return {};
  });

export const getCompanyLimits = (id) => axios.get(`/companies/${id}/limits`)
  .then(res => {
    if (res.data) {
      return res.data;
    }
    return {};
  });

export const getCompanyProducts = (id) => axios.get(`/companies/${id}/products`)
  .then(res => {
    if (res.data) {
      return res.data;
    }
    return {};
  });

export const getCompanyRecurrences = (id) => axios.get(`/companies/${id}/recurrences`)
  .then(res => {
    if (res.data) {
      return res.data;
    }
    return {};
  });

export const getCompanyCoupons = (id) => axios.get(`/companies/${id}/coupons`)
  .then(res => {
    if (res.data) {
      return res.data;
    }
    return {};
  });

export const getLogs = (id) => axios.get(`/companies/${id}/logs`)
  .then(res => {
    if (res.data) {
      return res.data;
    }
    return {};
  });

export const getCompanySettings = (id) => axios.get(`/companies/${id}/settings`)
  .then(res => {
    if (res.data) {
      return res.data;
    }
    return {};
  });

export const getCompanyContacts = (id) => axios.get(`/companies/${id}/contacts`)
  .then(res => {
    if (res.data) {
      return res.data;
    }
    return {};
  });

export const getCompanyOrders = (id) => axios.get(`/companies/${id}/orders`)
  .then(res => {
    if (res.data) {
      return res.data;
    }
    return {};
  });

export const getCompanyPendingOrders = (id) => axios.get(`/companies/${id}/orders/pending`)
  .then(res => {
    if (res.data) {
      return res.data;
    }
    return {};
  });
