<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";

  import DatePicker from "vue2-datepicker";
  
  import { getCompanyContacts } from '@/api/Companies';
  import { getOrder, sendNotification, updateDue } from '@/api/Orders.js';
  import { Order } from "@/models/Order";
  import { OrderNotification } from "@/models/OrderNotification";
  
  /**
   * Basic-table component
   */
  export default {
    page: {
      title: "Faturas",
      meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout, PageHeader, DatePicker },
    data() {
      return {
        title: "Faturas",
        forms:{
          notifications: {
            emails: ["fred.lima@huggy.io"]
          },
          due:{
            date: new Date()
          }
        },
        items: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Faturas",
            href: "/orders",
          },
          {
            text: "Detalhes",
            active: true,
          },
        ],
        order: new Order()
      };
    },
    created () {
      this.fetchData();
    },
    methods: {
      fetchData() {
        let vm = this
        getOrder(this.$route.params.id).then(response => {
            vm.order = new Order(response)

          getCompanyContacts(response.company.id).then(response => {
            vm.forms.notifications.emails = response.map(item => {
              return item.email
            }) || [vm.order.company.email]
          })
        })
      },
      addNotificationEmail() {
        this.forms.notifications.emails.push("");
      },
      deleteNotificationEmail(index) {
        this.forms.notifications.emails.splice(index, 1);
      },
      sendNotification(){
        let vm = this;
        
        this.$awn.async(
          sendNotification(vm.order.id, JSON.stringify(vm.forms.notifications.emails)),
          function (result) {
            result.map(function(notification){
              this.order.notifications.push(new OrderNotification(notification))
            })
            
            vm.$awn.success('Operação realizada com sucesso.')
          }, function () {
            vm.$awn.warning('Ocorreu um erro ao efetuar a operação. Favor tentar novamente.')
          }
        );
      },
      updateDue(){
        let vm = this;

        this.$awn.async(
          updateDue(this.order.id, this.forms.due.date),
          function (result) {
            vm.order.status = 'pendding'
            vm.order.class  = 'badge badge-warning'
            vm.order.due    = new Date(result.due)

            vm.$awn.success('Operação realizada com sucesso.')
          }, function () {
            vm.$awn.warning('Ocorreu um erro ao efetuar a operação. Favor tentar novamente.')
          }
        );
      },
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
      },
      handleOk(bvModalEvt){
        bvModalEvt.preventDefault()

        this.$refs.observer.validate().then(result => {
          if(result){
            this.sendNotification()
            this.$bvModal.hide('modal-notification')
          }
        });
      },
      handleDueOk(bvModalEvt){
        bvModalEvt.preventDefault()

        this.$refs.observer.validate().then(result => {
          if(result){
            this.updateDue()
            this.$bvModal.hide('modal-due')
          }
        });
      }
    },
    computed: {
      hasPayments: function () {
        return this.order.payments.length > 0
      },
      hasInvoices: function () {
        return this.order.invoices.length > 0
      },
      hasFailedPayments: function () {
        return this.order.failed_payments.length > 0
      },
      hasNotifications: function () {
        return this.order.notifications.length > 0
      },
    },
  };
</script>

<template>
  <Layout>
    <PageHeader
      :title="title"
      :items="items"
    />
    
    <div class="card">
      <div class="card-body">
        <h4 class="mb-3 font-size-18">
          Detalhes
  
          <b-dropdown right size="sm" variant="secondary" style="float:right;">
            <template slot="button-content">
              Ações
              <i class="bx bx-dots-vertical" />
            </template>
            <b-dropdown-item @click="$bvModal.show('modal-notification')">Enviar fatura</b-dropdown-item>
            <b-dropdown-item @click="$bvModal.show('modal-due')">Alterar vencimento</b-dropdown-item>
            <b-dropdown-item :href="`https://payment.huggy.io/?token=${order.token}`" target="_blank">Página de pagamento</b-dropdown-item>
          </b-dropdown>
        </h4>
  
        <div class="table-responsive mb-0">
          <table class="table">
            <tbody>
            <tr>
              <th scope="row">
                Empresa:
              </th>
              <td>
                <router-link
                  tag="a"
                  :to="`/companies/${order.company.id}`"
                  class="text-primary"
                >
                  {{ order.company.name }}
                </router-link>
              </td>
              <th scope="row">
                Vencimento:
              </th>
              <td>{{ $d(order.due, 'short')}}</td>
              <th scope="row">
                Criada em:
              </th>
              <td>{{ $d(order.created_at, 'long')}}</td>
            </tr>
            <tr>
              <th scope="row">
                Status:
              </th>
              <td><span :class="order.class">{{ $t(`global.status.${order.status}`) }}</span></td>
              <th scope="row">
                Origem:
              </th>
              <td>{{ $t(`global.roles.${ order.source }`) }}</td>
              <th scope="row">
              </th>
              <td></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  
    <div class="card">
      <div class="card-body">
        <h4 class="mb-3 font-size-18">
          Itens
        </h4>
  
        <div class="table-responsive mb-0">
          <table class="table">
            <thead>
            <tr>
              <th style="width: 70px;">
                Qtd.
              </th>
              <th>Item</th>
              <th class="text-right">
                Preço
              </th>
              <th class="text-right">
                Subtotal
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in order.items" v-bind:key="item.id" v-show="item.quantity > 0">
              <td>{{ item.quantity }}x</td>
              <td>{{ item.description }}</td>
              <td class="text-right">
                {{ $n(item.price, 'currency', order.currency) }}
              </td>
              <td class="text-right">
                {{ $n(item.quantity * item.price, 'currency', order.currency) }}
              </td>
            </tr>
            <tr>
              <td
                colspan="3"
                class="text-right"
              >
                Sub Total
              </td>
              <td class="text-right">
                {{ $n(order.amount, 'currency', order.currency) }}
              </td>
            </tr>
            <tr>
              <td
                colspan="3"
                class="border-0 text-right"
              >
                <strong>Desconto</strong>
              </td>
              <td class="border-0 text-right">
                {{ $n(order.discount, 'currency', order.currency) }}
              </td>
            </tr>
            <tr>
              <td
                colspan="3"
                class="border-0 text-right"
              >
                <strong>Total</strong>
              </td>
              <td class="border-0 text-right">
                <h4 class="m-0">
                  {{ $n(order.final_amount, 'currency', order.currency) }}
                </h4>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  
    <div class="card">
      <div class="card-body">
        <h4 class="mb-3 font-size-18">
          Pagamentos
        </h4>
  
        <div class="table-responsive mb-0">
          <table class="table">
            <thead>
            <tr>
              <th style="width: 70px;">
                #
              </th>
              <th>Referência</th>
              <th class="text-right">
                Valor
              </th>
              <th>
                Método
              </th>
              <th class="min">
                Data
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in order.payments" v-show="hasPayments" v-bind:key="item.id">
              <td>{{ item.id }}</td>
              <td>{{ item.reference }}</td>
              <td class="text-right">
                {{ $n(item.amount, 'currency', order.currency) }}
              </td>
              <td>
                {{ $t(`global.methods.${item.method}`)}}
              </td>
              <td class="min">
                {{ $d(item.created_at, 'long') }}
              </td>
            </tr>
            <tr v-if="!hasPayments">
              <td colspan="100%" class="text-center">
                Nenhum resultado encontrado.
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  
    <div class="card">
      <div class="card-body">
        <h4 class="mb-3 font-size-18">
          Notas Fiscais
        </h4>
  
        <div class="table-responsive mb-0">
          <table class="table">
            <thead>
            <tr>
              <th style="width: 70px;">
                Código
              </th>
              <th>Protoolo</th>
              <th>
                Número
              </th>
              <th>
                Chave
              </th>
              <th class="min">
                Data
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in order.invoices" v-show="hasInvoices" v-bind:key="item.id">
              <td>{{ item.code }}</td>
              <td>{{ item.protocol }}</td>
              <td>{{ item.number }}</td>
              <td>{{ item.key }}</td>
              <td class="min">
                {{ $d(item.created_at, 'long') }}
              </td>
            </tr>
            <tr v-if="!hasInvoices">
              <td colspan="100%" class="text-center">
                Nenhum resultado encontrado.
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  
    <div class="card">
      <div class="card-body">
        <h4 class="mb-3 font-size-18">
          Falhas de Pagamento
        </h4>
      
        <div class="table-responsive mb-0">
          <table class="table">
            <thead>
            <tr>
              <th style="width: 70px;">
                Método
              </th>
              <th>
                Resposta
              </th>
              <th class="min">
                Data
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in order.failed_payments" v-show="hasFailedPayments" v-bind:key="item.id">
              <td>{{ $t(`global.methods.${item.payment_method}`) }}</td>
              <td>{{ item.response }}</td>
              <td class="min">
                {{ $d(item.created_at, 'long') }}
              </td>
            </tr>
            <tr v-if="!hasFailedPayments">
              <td colspan="100%" class="text-center">
                Nenhum resultado encontrado.
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  
    <div class="card">
      <div class="card-body">
        <h4 class="mb-3 font-size-18">
          Notificações
        </h4>
      
        <div class="table-responsive mb-0">
          <table class="table">
            <thead>
            <tr>
              <th style="width: 70px;">
                Canal
              </th>
              <th>
                Para
              </th>
              <th>
                Status
              </th>
              <th class="min">
                Data
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in order.failed_payments" v-show="hasFailedPayments" v-bind:key="item.id">
              <td>{{ $t(`global.channels.${item.channel}`) }}</td>
              <td>{{ item.to }}</td>
              <td>
                <span :class="item.class">{{ $t(`global.status.${item.status}`) }}</span>
              </td>
              <td class="min">
                {{ $d(item.created_at, 'long') }}
              </td>
            </tr>
            <tr v-if="!hasNotifications">
              <td colspan="100%" class="text-center">
                Nenhum resultado encontrado.
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  
    <b-modal
      id="modal-notification"
      centered
      title="Enviar Fatura"
      title-class="font-18"
      :cancel-title="'Cancelar'"
      :ok-title="'Enviar'"
      @ok="handleOk"
    >
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form
          @submit.stop.prevent="handleSubmit(sendNotification)"
          class="form-horizontal"
        >
          <div class="inner-repeater mb-4">
            <div
              v-for="(data , index) in forms.notifications.emails"
              :key="data.email"
              class="mb-1"
            >
              <validation-provider
                name="E-mail"
                :rules="{ required: true, email: true }"
                v-slot="email"
              >
                <b-input-group>
                  <b-form-input
                    for="email"
                    v-model="forms.notifications.emails[index]"
                    id="input-user-name"
                    name="email"
                    :state="getValidationState(email)"
                  />
                  <b-input-group-append>
                    <b-button variant="primary" @click="deleteNotificationEmail(index)"><i class="bx bx-trash"></i></b-button>
                  </b-input-group-append>
                </b-input-group>
  
                <b-form-invalid-feedback>{{ email.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </div>
  
            <b-button
              variant="primary"
              @click="addNotificationEmail"
              style="float: right"
            >Adicionar E-mail</b-button>
          </div>
          
          <template v-slot:modal-footer>
            <b-button size="sm" variant="success" type="submit">
              Enviar
            </b-button>
            <b-button size="sm" variant="danger" @click="$bvModal.hide('modal-user')">
              Cancelar
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  
    <b-modal
      id="modal-due"
      centered
      size="sm"
      title="Alterar vencimento"
      title-class="font-18"
      :cancel-title="'Cancelar'"
      :ok-title="'Alterar'"
      @ok="handleDueOk"
    >
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form
          @submit.stop.prevent="handleSubmit(updateDue)"
          class="form-horizontal"
        >
  
          <validation-provider
            name="Data"
            :rules="{ required: true }"
            v-slot="date"
          >
            <b-form-group
              label-cols-sm="2"
              label-cols-lg="2"
              label="Data"
              label-for="text"
            >
              <date-picker
                v-model="forms.due.date"
                :first-day-of-week="1"
                lang="pt-BR"
                :format="'DD/MM/YYYY'"
                :value-format="'YYYY-MM-DD'"
                :default-value="new Date()"
                :disabled-date="(date) => {return date.getTime() <= new Date().getTime()}"
                :editable="false"
                :clearable="false"
              />
              
              <b-form-invalid-feedback>{{ date.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        
          <template v-slot:modal-footer>
            <b-button size="sm" variant="success" type="submit">
              Salvar
            </b-button>
            <b-button size="sm" variant="danger" @click="$bvModal.hide('modal-due')">
              Cancelar
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </Layout>
</template>
