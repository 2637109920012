import { axios } from '@/plugins/axios';

export const getOrders = (params) => axios.get('/orders', {params: params})
  .then(res => res.data);


export const getOrder = (id) => axios.get(`/orders/${id}`)
  .then(res => {
    if (res.data) {
      return res.data;
    }
    return {};
  });


export const createOrder = (order) => {
  return axios
    .post(`/orders`, order)
    .then(res => {
      if (res.data) {
        return res.data;
      }
      return {};
    });
};


export const deleteOrder = (id) => axios.delete(`/orders/${id}`)
  .then(res => res.data);


export const processOrder = (id, params) => {
  return axios
    .post(`/orders/${id}/process`, params)
    .then(res => {
      if (res.data) {
        return res.data;
      }
      return {};
    });
};

export const payOrder = (id, params) => {
  return axios
    .post(`/orders/${id}/payment`, params)
    .then(res => {
      if (res.data) {
        return res.data;
      }
      return {};
    });
};

export const updateDue = (id, due) => {
  return axios
    .put(`/orders/${id}/due`, {due: due})
    .then(res => {
      if (res.data) {
        return res.data;
      }
      return {};
    });
};

export const sendNotification = (id, email) => {
  return axios
    .put(`/orders/${id}/notification`, {emails: email})
    .then(res => {
      if (res.data) {
        return res.data;
      }
      return {};
    });
};

export const finishOrder = (id) => {
  return axios
    .get(`/orders/${id}/finish`)
    .then(res => {
      if (res.data) {
        return res.data;
      }
      return {};
    });
};

export const chargeOrder = (id) => {
  return axios
    .get(`/orders/${id}/charge`)
    .then(res => {
      if (res.data) {
        return res.data;
      }
      return {};
    });
};

